import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import founders from '../assets/images/pics/flow-founders.jpg';
import moneybox from '../assets/images/pics/flow-moneybox.jpg';

const DragonsDen = (page: PageProps) => {
  const { t } = useTranslation('dragonsDen');

  return (
    <Layout>
      <Seo page={page} title="Flow Your Money in Dragons’ Den" />
      <main className="page-dragons-den">
        <section className="section-1">
          <div className="container">
            <div className="section-1__content u-spacing-large">
              <h2 className="section-1__title u-title-primary">
                <Trans
                  defaults="<0>The Smart Money Distributor</0> In Dragons’ Den"
                  i18nKey="dragonsDen:title"
                  components={[<div className="u-color-violet" />]}
                />
              </h2>
              <div className="section-1__text u-text-primary u-color-gray">
                {t(
                  'description',
                  `On Thursday, March 11, founders Niels Mulder and Daan van Klinken presented Flow in the NPO1 program Dragons' Den.`
                )}
              </div>
              <a
                href="https://www.youtube.com/watch?v=I1IrCALTdcM"
                rel="noreferrer noopener"
                target="_blank"
                className="btn btn--primary"
              >
                {t('buttonTitle', 'Watch the episode')}
              </a>
              <div className="section-1__small u-color-gray">
                {t('textSmall', 'Starts at 25 minutes')}
              </div>
              <img src={founders} alt="founders" className="section-1__img" />
              <div className="section-1__description u-color-gray">
                <Trans
                  i18nKey="dragonsDen:article"
                  components={[
                    <a
                      href="https://www.youtube.com/watch?v=I1IrCALTdcM"
                      rel="noreferrer noopener"
                      target="_blank"
                      className="u-color-secondary"
                    />,
                  ]}
                />
              </div>
              <img src={moneybox} alt="moneybox" className="section-1__img" />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DragonsDen;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
